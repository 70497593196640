import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from '../components/seo'
import "moment/locale/sv"

const PressTemplate = ({ data: { press }, pageContext }) => {
  return (
    <Layout
      className="press"
      language={pageContext.language}
      template={pageContext.template}
    >
      <Seo title={press.title} template={pageContext.template} seo={press.seo} />
      <section className="content text-center">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="col-12 subTitle">
                  <h4>Pressmeddelande</h4>
                </div>
                <div className="col-12 title" itemProp="name">
                  <h1>{parse(press.title)}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content text-left">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="  col-12 col-md-10 col-lg-8">
              <div className="row">
                <div className="col-12 main-content columns-1">
                  {parse(press.pressData.text)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PressTemplate

export const pageQuery = graphql`
  query press($id: String!) {
    press: wpPressrelease(id: { eq: $id }) {
      id
      title
      uri
      slug
      date
      pressData {
        text
      }
      seo {
        canonical
        metaDesc
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          localFile {
            publicURL
          }
        }
        schema {
          pageType
          raw
        }
      }
    }
  }
`
